










































































import { Vue, Component } from "vue-property-decorator";
import { JWTModule } from "@/store/modules";
import CaptchaValidation from "@/components/NewUIComponents/captcha.vue";
import CaptchaModule from "@/store/modules/captcha";
import { Captcha } from "@/store/models/captcha";
import { eventHub } from "@/utils/eventHub";


@Component({
  components: {
    CaptchaValidation,
  },
})
export default class ForgotPassword extends Vue {
  email: string = "";
  captchaValid: boolean = true;
  captcha_obj: Captcha = {} as Captcha;
  isValidated: boolean = false;
  $refs!: {
    captchaForgetPassword: InstanceType<typeof CaptchaValidation>;
  };
  
  resetCaptchaValidation() {
    this.captchaValid = true;
  }

  checkCaptchaInput(input_value: string, captcha_id: number) {
    this.captcha_obj.id = captcha_id;
    this.captcha_obj.textFromUser = input_value;
    return this.captcha_obj;
  }

  async created() {
    await CaptchaModule.getCaptcha();
  }

  async forgotPassword(e: any) {
    let isValid = await this.$validator.validateAll();
    let valid = await this.$refs.captchaForgetPassword.$validator.validateAll("login");

    if (isValid && valid) {
      if (
        this.captcha_obj.textFromUser &&
        this.captcha_obj.textFromUser != ""
      ) {
        this.captchaValid = true;
        const response = await CaptchaModule.validateCaptcha(this.captcha_obj);
        this.isValidated = response.validated;
        this.captchaValid = response.validated;
      }
    }
    if (!this.isValidated) {
      eventHub.$emit("clear-captcha-input-field");
    }
    if (this.isValidated) {
      const response = await JWTModule.resetPassword({ email: this.email });
      if (response.message) {
        this.$snotify.success(response.message);
      }
      this.$router.push({ name: "NewHome" });
    }
  }

  onLoginClick() {
    this.$bvModal.show("login");
  }
}
