























































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import CaptchaModule from "@/store/modules/captcha";
import { eventHub } from "@/utils/eventHub";

@Component
export default class CaptchaNew extends Vue {
  inputCaptcha = "";

  @Watch("inputCaptcha", { immediate: true })
  onInputCaptchaChange(newVal: string, oldVal: string) {
    if (newVal != "") {
      this.$emit("reset-validation");
    }
  }

  @Prop({ default: true })
  isValid!: string;

  async created() {
    // Here captcha is fetched from loginModal.vue during
    // login Modal popup so we don't have get api in created.
    // Only clearing input field on errors(incorrect validation) is
    // performed here.

    eventHub.$on("clear-captcha-input-field", this.clearInputField);
  }

  get captcha() {
    return CaptchaModule.captcha;
  }

  async refreshCaptcha() {
    await CaptchaModule.getCaptcha();
  }

  checkValidation() {
    this.$emit("verification", this.inputCaptcha, this.captcha.id);
  }

  async clearInputField(reset: boolean = false) {
    this.inputCaptcha = "";
    if (reset) {
      this.$validator.reset();
    }
    await CaptchaModule.getCaptcha();
  }
}
