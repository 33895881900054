import {
  VuexModule,
  getModule,
  MutationAction,
  Action,
  Module,
} from "vuex-module-decorators";
import store from "@/store";
import api from "@/store/api/captcha";
import { Captcha } from "@/store/models/captcha";

@Module({
  name: "CaptchaModule",
  dynamic: true,
  store,
})
class CaptchaModule extends VuexModule {
  captcha: Captcha = {} as Captcha;

  @MutationAction
  async getCaptcha() {
    const response = await api.getCaptcha();
    return { captcha: response };
  }

  @Action
  async validateCaptcha(obj: any) {
    const response = await api.postCaptcha(obj);
    return response;
  }
}
export default getModule(CaptchaModule);
