import { getApi, postApi } from "@/store/api/api";
import { Action } from "@/store/actionType";
import { Captcha } from "../models/captcha";

export default new (class CaptchaAPI {
  public async getCaptcha(): Promise<Captcha> {
    const action = Action.createCaptcha;
    const response = await getApi(action);

    return response as Captcha;
  }

  public async postCaptcha(obj: any): Promise<any> {
    const action = "captcha/" + `${obj.id}/` + "validate_captcha/";
    const response = await postApi(action, obj);

    return response;
  }
})();
